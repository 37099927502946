<template>
  <div class="content flex" style="margin-bottom:40px">
    <div class="detail-left flex1">
      <ul class="pc-breadcrumb flex">
        <li class="pr5">
          <router-link to="/">{{companyConfig.firstName+companyConfig.lastName}} > </router-link>
        </li>
        <li class="pr5">
          <router-link to="/kandian">房产看点 > </router-link>
        </li>
        <li>
          <a href="javascript:;">正文</a>
        </li>
      </ul>
      <iframe :src="weburl" style="width:100%;height:670px" frameBorder="0" />
    </div>
    <div class="detail-right" style="width:360px">
      <div class="more-recommend-container ">
        <h3>更多推荐</h3>
        <ul>
          <recommen-highlight-item v-for="article in articles" :key="article.guid" :article="article" />
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import tool from "@/utils/tool";
import houseServer from "@/services/houseServer.js";
import RecommenHighlightItem from "./component/RecommenHighlightItem";
import Constant from "@/common/Constant";
import { DEV_BASE_URL, PRO_BASE_URL } from "@/common/Url";
import { mapState } from "vuex";
import {  getItem } from '../../utils/storage'
export default {
  name: "lighlightDetail",
  data() {
    return {
      articles: [],
      weburl: ""
    };
  },
  components: {
    RecommenHighlightItem
  },
  computed: {
    ...mapState(["user","companyConfig"])
  },
  methods: {
    async loadArticle() {
      const param = {
        page: 1,
        itemsPerPage: 5,
        categoryGuid: "580f3e4deefc451d9fee553c1af5a74e",
        companyGuid: getItem(Constant.CITY_COMPANY).cityCompanyGuid,
        pcOrMobile:true
      };
      const [
        err,
        {
          data: { records }
        }
      ] = await tool.to(houseServer.customerArticle(param));
      if (err) {
        console.log("customerArticle :>>", err);
      }
      this.articles = [...records];

      //   console.log("data :>>", JSON.stringify(records));
    }
  },
  created() {
    this.loadArticle();
    const {
      params: { articleGuid }
    } = this.$route;
    const BASE =
      Constant.MODE === Constant.PRO_MODE ? PRO_BASE_URL : DEV_BASE_URL;
    this.weburl = BASE + "/api" + Constant.ARTICLE_URL + articleGuid;
  }
};
</script>
<style lang='less' scoped>
.pc-breadcrumb {
  margin: 30px 0;
  a {
    color: #9399a5;
    font-size: 12px;
  }
}
iframe {
  padding: 15px 0;
  box-sizing: border-box;
  overflow: auto;
  color: #101d37;
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid #e5e5e6;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.16);
}
.more-recommend-container {
  color: #000;
  padding: 0;
  width: 340px;
  border: 1px solid #e4e6f0;
  border-radius: 2px;
  margin: 78px 0 0 20px;
  h3 {
    font-size: 16px;
    color: #101d37;
    font-weight: 700;
    margin: 24px 0 12px;
    border-left: 4px solid #00ae66;
    padding-left: 16px;
  }
}
</style>