<template>
  <li>
    <router-link :to="path" target="_blank" class="flex container sb_center cursor">
      <div class="flex flex1 column sb_start" style="height:80px">
        <span class="black bold">{{article.title}}</span>
        <span style="color:#9399a5">{{article.createTime}}</span>
      </div>
      <img :src="tempImgs" width="105" height="80" />
    </router-link>
  </li>

</template>
<script>
import Constant from "@/common/Constant";

export default {
  name: "recommendHighlightItem",
  props: {
    article: Object
  },
  computed: {
    path() {
      return `/detail/${this.article.guid}`;
    },
    tempImgs() {
      const _tmpImages = this.article.tmpImages;
      const imgs = _tmpImages.split(",");
      let ret = Constant.DEFAULT_HOUSE;
      if (!_tmpImages) return ret;
      for (let i = 0; i < imgs.length; i++) {
        if (!i && imgs[i]) {
          ret = imgs[i];
        } else {
          if (ret) break;
          if (!ret) {
            ret = imgs[i];
          }
        }
      }
      return ret;
    }
  }
};
</script>
<style lang='less' scoped>
.container {
  max-height: 80px;
  width: calc(100% - 40px);
  margin: 0 20px;
  padding: 21px 0;
  box-sizing: content-box;
  position: relative;
}
</style>